import React, { useEffect, useState } from "react";
import Header from "../components/header.js";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Footer from "../components/footer.js";
import ArtCard from '../components/artCard.js';
import ArtData from "../artProjects.json"




function Art2() {
    const [artProjects, setArtProjects] = useState([]);


  const buttonVariants = {
    visible: { opacity: 1, scale: 1, transition: { duration: 1 } },
    hidden: { opacity: 0, scale: 0.5 },
  };

  const buttonControls = useAnimation();
  const { ref: buttonRef, inView: buttonInView } = useInView();

  useEffect(() => {
    setArtProjects(ArtData)
}, []);


  useEffect(() => {
    if (buttonInView) {
      buttonControls.start("visible");
    }
  }, [buttonControls, buttonInView]);

  return (
    <div className="min-h-screen bg-gradient-to-tr from-gray-800 via-black to-black flex flex-col text-center items-center">
      <Header />
      <div className="mt-32 flex flex-col gap-20">
            {artProjects.map((project, index) => (
                <ArtCard key={index} {...project} />
            ))}
      </div>
      <div className="mt-32">
        <motion.button
          ref={buttonRef}
          initial="hidden"
          animate={buttonControls}
          variants={buttonVariants}
          className="inline-flex text-xl items-center justify-center h-16 px-14 py-3 font-semibold text-center text-gray-200 no-underline align-middle transition-all duration-300 ease-in-out bg-transparent border-2 border-gray-600 border-solid rounded-full cursor-pointer select-none hover:text-white hover:border-white focus:shadow-xs focus:no-underline"
          onClick={() => {
            window.open(
              "https://photos.app.goo.gl/dSjVWzmDotfoAfax5",
              "_blank"
            );
          }}
        >
          Extended Portfolio
        </motion.button>
      </div>

      <div className="mt-32">
        <Footer />
      </div>
    </div>
  );
}

export default Art2;
