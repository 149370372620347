import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Code from './pages/Code';
import Art from './pages/Art';


function App() {
  return (
    <>
    <Routes>
       <Route path="/" element={<Home />} />
       <Route path="/code" element={<Code />} />
       <Route path="/art" element={<Art />} />
    </Routes>
 </>
  );
}

export default App;
