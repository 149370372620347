import React, { useState, useEffect } from 'react';
import '../Hero.css';



const Hero = () => {
    const [currentWord, setCurrentWord] = useState("");
    const [intervalTime, setIntervalTime] = useState(20);
    const [isComplete, setIsComplete] = useState(false);
    const [words, setWords] = useState(["Passion", "World", "Life", "Journey", "Story", "Vision", "Creation", "Universe", "Dreams", "Odyssey", "Gallery", "Echo", "Progression", "Haven", "Pursuit"])

    useEffect(() => {
        // Randomizes words use state array using fisher-yates shuffle algorithm
        for (let i = words.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [words[i], words[j]] = [words[j], words[i]];
        }
    }, []);


    useEffect(() => {
        if (isComplete) return;

        const maxInterval = 800;
        const intervalIncrease = 1.3;

        const interval = setInterval(() => {
            if(intervalTime >= maxInterval) {
                setIsComplete(true);
                clearInterval(interval); 
            } else {
                console.log(words)
                let word = words.shift()
                console.log(words)
                setCurrentWord(word);
                setIntervalTime(prev => prev * intervalIncrease);
            }
        }, intervalTime);

        return () => clearInterval(interval); // Cleanup on unmount
    }, [intervalTime, isComplete]);

    return (
        <div>
        <div className='text-5xl font-semibold  text-white leading-tight'>Welcome to my <br/> <span className='tracking-wide animated-gradient'>{currentWord}</span> </div>
        {/* <div className='text-3xl font-semibold  text-white leading-tight mt-32'>
            I'm Matthew, a creator 
        </div> */}
        </div>
    );
};

export default Hero;
