import React from "react";
import logo from "../logo.png";
import { Link, useLocation } from "react-router-dom";

export default function Header() {
  const location = useLocation();

  // Determine if on the main page
  const isMainPage = location.pathname === "/";

  return (
    <div className="flex flex-row justify-between px-2 backdrop-blur-md bg-black/20 h-12 lg:h-16 w-80 lg:w-96 mt-6 rounded-full text-2xl lg:text-3xl font-serif font-semibold text-white sticky top-16 z-50">
    <div className="flex w-24 items-center justify-center">
      <Link to="/art">Art</Link>
    </div>
    <div className="flex items-center justify-center">
      <Link to="/">
        <img className={`w-10 h-auto rounded-lg transform transition-transform hover:rotate-180 transition-duration-3000 ${isMainPage ? "" : ""}`} src={logo} alt="Logo" />
      </Link>
    </div>
    <div className="flex w-24 items-center justify-center">
      <Link to="/code">Code</Link>
    </div>
  </div>
  );
}
