import React, { useState } from "react";
import abyssFullShot from "../images/landAndSea/Abyss Table top side 2.jpg";
import abyssSlideTop from "../images/landAndSea/Abyss Table top side-1.jpg";
import abyssTop from "../images/landAndSea/Abyss Table top side 3-1.jpg";
import abyssSlide from "../images/landAndSea/Abyss Table side 1.jpg";
import archTopShot from "../images/landAndSea/Bridge Table 45d 1-1.jpg";
import archLight from "../images/landAndSea/Bridge Table top side shadows.jpg";
import archNoTop from "../images/landAndSea/Bridge Table 45d no top.jpg";
import archFull from "../images/landAndSea/Bridge Table 45d-1.jpg";

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

function FeatureLandSea() {
  const [isMareInView, setIsMareInView] = useState(false);
  const contentVariants = {
    offscreen: {
      y: 100,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 10,
      },
    },
  };
  return (
    <div className="w-full overflow-x-scroll overflow-y-hidden">
      <InView threshold={0.5} triggerOnce>
        {({ inView, ref }) => (
          <motion.div
            ref={ref}
            variants={contentVariants}
            initial="offscreen"
            animate={inView ? "onscreen" : "offscreen"}
          >
            <div className="overflow-x-scroll">
              <div className="flex flex-row items-center h-96 w-screen opacity-70 animate-slide gap-7">
                <img
                  src={abyssSlideTop}
                  className="h-full object-cover rounded-2xl"
                  alt="Art"
                />
                <img
                  src={archTopShot}
                  className="h-full object-cover rounded-2xl"
                  alt="Art"
                />
                <img
                  src={abyssTop}
                  className="h-full object-cover rounded-2xl"
                  alt="Art"
                />
                <img
                  src={archLight}
                  className="h-full object-cover rounded-2xl"
                  alt="Art"
                />
                <img
                  src={abyssSlide}
                  className="h-full object-cover rounded-2xl"
                  alt="Art"
                />
                <img
                  src={archNoTop}
                  className="h-full object-cover rounded-2xl"
                  alt="Art"
                />
                <img
                  src={abyssFullShot}
                  className="h-full object-cover rounded-2xl"
                  alt="Art"
                />
                <img
                  src={archFull}
                  className="h-full object-cover rounded-2xl"
                  alt="Art"
                />
                {/* Repeat Buffer Images */}
                <img
                  src={abyssSlideTop}
                  className="h-full object-cover rounded-2xl"
                  alt="Art"
                />
                <img
                  src={archTopShot}
                  className="h-full object-cover rounded-2xl"
                  alt="Art"
                />
                <img
                  src={abyssTop}
                  className="h-full object-cover rounded-2xl"
                  alt="Art"
                />
                <img
                  src={archLight}
                  className="h-full object-cover rounded-2xl"
                  alt="Art"
                />
                <img
                  src={abyssSlide}
                  className="h-full object-cover rounded-2xl"
                  alt="Art"
                />
                <img
                  src={archNoTop}
                  className="h-full object-cover rounded-2xl"
                  alt="Art"
                />
                <img
                  src={abyssFullShot}
                  className="h-full object-cover rounded-2xl"
                  alt="Art"
                />
                <img
                  src={archFull}
                  className="h-full object-cover rounded-2xl"
                  alt="Art"
                />
              </div>
            </div>
    
              <div className="text-3xl font-semibold  text-white leading-tight mt-10">
              Ex Terra et <span className={isMareInView ? 'text-cyan-500' : 'text-white' }>Mare</span>
              </div>

          </motion.div>
        )}
      </InView>
    </div>
  );
}

export default FeatureLandSea;
