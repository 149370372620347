import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {

    function onChat(){
      var mailtoLink = "mailto:matthewsalaway@gmail.com";
      var opened = window.open(mailtoLink, '_self');
      
      if (!opened) {
          // Handle the case where the email client did not open.
          alert("Please manually open your email client and send an email to matthewsalaway@gmail.com :)");
      }
    }

  return (
    <div className="flex flex-col items-center mb-10 w-screen">
      <button
        onClick={onChat}
        className="relative inline-flex items-center justify-center px-6 lg:px-12 py-3 lg:py-4 overflow-hidden font-bold text-white rounded-md shadow-2xl group w-52 lg:w-72 lg:text-xl"
      >
        <span className="absolute inset-0 w-full h-full transition duration-300 ease-out opacity-0 bg-gradient-to-br from-violet-800 via-blue-700 to-sky-600 group-hover:opacity-100"></span>
        <span className="absolute top-0 left-0 w-full bg-gradient-to-b from-white to-transparent opacity-5 h-1/3"></span>
        <span className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t from-white to-transparent opacity-5"></span>
        <span className="absolute bottom-0 left-0 w-4 h-full bg-gradient-to-r from-white to-transparent opacity-5"></span>
        <span className="absolute bottom-0 right-0 w-4 h-full bg-gradient-to-l from-white to-transparent opacity-5"></span>
        <span className="absolute inset-0 w-full h-full border border-white rounded-md opacity-10"></span>
        <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-56 group-hover:h-56 opacity-5"></span>
        <span className="relative">Let's Connect</span>
      </button>
      <div className="flex flex-row items-center gap-4 w-full justify-evenly mt-4">
        <div className="text-white mt-4 text-sm lg:text-lg">@ 2024 Matthew Salaway</div>
        <div className="flex space-x-5 mt-4 lg:space-x-8">
          <a
            href="https://github.com/Matthew-Salaway"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white text-xl lg:text-3xl"
          >
            <FontAwesomeIcon icon={faGithub}  />
          </a>
          <a
            href="https://www.linkedin.com/in/msalaway/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white text-xl lg:text-3xl"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          {/* <a
            href="https://www.instagram.com/matthew_salaway"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white text-xl lg:text-3xl"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
