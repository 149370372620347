import Header from "../components/header.js";
import React, { useState, useEffect } from "react";
import projectData from "../codeProjects.json"
import ProjectCard from "../components/projectCard.js";
import Footer from "../components/footer.js";

function Code() {
  // State to keep track of the active button
  const [activeButton, setActiveButton] = useState(null);
  // state to track active projects
  const [activeProjects, setActiveProjects] = useState([]);

  // Function to compare dates in month/year format
  const compareDates = (a, b) => {
    const [monthA, yearA] = a.date.split('/').map(Number);
    const [monthB, yearB] = b.date.split('/').map(Number);
    const dateA = new Date(yearA, monthA - 1);
    const dateB = new Date(yearB, monthB - 1);
    return dateB - dateA;
  };

  useEffect(() => {
    // Sort projectData based on dates
    const sortedProjects = [...projectData].sort(compareDates);
    setActiveProjects(sortedProjects);
  }, []);

  useEffect(() => {
    let filteredProjects = projectData;
    if (activeButton) {
      filteredProjects = projectData.filter((project) => project.category.includes(activeButton));
    }
    setActiveProjects(filteredProjects.sort(compareDates));
  }, [activeButton]);



  // Function to handle button click
  const handleButtonClick = (button) => {
    // if the button is already active, then set to null
    // otherwise set it to active
    activeButton === button ? setActiveButton(null) : setActiveButton(button);
    
  };

  // Function to determine if a button is active
  const isActive = (button) => activeButton === button;
  return (
    <div className="min-h-screen bg-gradient-to-tr from-gray-800 via-black to-black flex flex-col text-center items-center">
      <Header />
      <div className="text-blue-100 mt-32 flex flex-row gap-4 md:text-xl md:gap-6">
        <button
          onClick={() => handleButtonClick("AI")}
          className={`relative inline-flex items-center justify-center px-6 py-3 md:px-12 md:py-4 overflow-hidden font-bold text-white rounded-md shadow-2xl group opacity-100`}
        >
          <span className={`absolute inset-0 w-full h-full transition duration-300 ease-out bg-gradient-to-br from-violet-800 via-blue-700 to-sky-600 ${isActive('AI') ? 'opacity-100' : 'opacity-0'} `}></span>
          <span className="absolute top-0 left-0 w-full bg-gradient-to-b from-white to-transparent opacity-5 h-1/3"></span>
          <span className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t from-white to-transparent opacity-5"></span>
          <span className="absolute bottom-0 left-0 w-4 h-full bg-gradient-to-r from-white to-transparent opacity-5"></span>
          <span className="absolute bottom-0 right-0 w-4 h-full bg-gradient-to-l from-white to-transparent opacity-5"></span>
          <span className="absolute inset-0 w-full h-full border border-white rounded-md opacity-10"></span>
          <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-56 group-hover:h-56 opacity-5"></span>
          <span className="relative">AI</span>
        </button>

        <button
          onClick={() => handleButtonClick("Creative")}
          className={`relative inline-flex items-center justify-center px-6 py-3 overflow-hidden font-bold text-white rounded-md shadow-2xl group opacity-100`}
        >
          <span className={`absolute inset-0 w-full h-full transition duration-300 ease-out bg-gradient-to-br from-violet-800 via-blue-700 to-sky-600 ${isActive('Creative') ? 'opacity-100' : 'opacity-0'} `}></span>
          <span className="absolute top-0 left-0 w-full bg-gradient-to-b from-white to-transparent opacity-5 h-1/3"></span>
          <span className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t from-white to-transparent opacity-5"></span>
          <span className="absolute bottom-0 left-0 w-4 h-full bg-gradient-to-r from-white to-transparent opacity-5"></span>
          <span className="absolute bottom-0 right-0 w-4 h-full bg-gradient-to-l from-white to-transparent opacity-5"></span>
          <span className="absolute inset-0 w-full h-full border border-white rounded-md opacity-10"></span>
          <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-56 group-hover:h-56 opacity-5"></span>
          <span className="relative">Creative</span>
        </button>

        <button
          onClick={() => handleButtonClick("Blockchain")}
          className={`relative inline-flex items-center justify-center px-6 py-3 overflow-hidden font-bold text-white rounded-md shadow-2xl group opacity-100`}
        >
          <span className={`absolute inset-0 w-full h-full transition duration-300 ease-out bg-gradient-to-br from-violet-800 via-blue-700 to-sky-600 ${isActive('Blockchain') ? 'opacity-100' : 'opacity-0'} `}></span>
          <span className="absolute top-0 left-0 w-full bg-gradient-to-b from-white to-transparent opacity-5 h-1/3"></span>
          <span className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t from-white to-transparent opacity-5"></span>
          <span className="absolute bottom-0 left-0 w-4 h-full bg-gradient-to-r from-white to-transparent opacity-5"></span>
          <span className="absolute bottom-0 right-0 w-4 h-full bg-gradient-to-l from-white to-transparent opacity-5"></span>
          <span className="absolute inset-0 w-full h-full border border-white rounded-md opacity-10"></span>
          <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-56 group-hover:h-56 opacity-5"></span>
          <span className="relative">Blockchain</span>
        </button>
      </div>
      {/* Project Cards */}
      <div className="mt-24 grid grid-cols-1 gap-y-16 gap-x-14 lg:grid-cols-2 mx-10 md:mx-24">
        {activeProjects.map((project) => (
         // project card component
          <ProjectCard key={project.id} project={project} />
        ))}
      </div>
      <div className="mt-32">
          <Footer />
      </div>
    </div>
  );
}

export default Code;
