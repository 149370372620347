import React, {useState} from "react";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLink, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion"; // Import motion

const ProjectCard = ({ project }) => {
    const [showCollaborators, setShowCollaborators] = useState(false);

  const cardVariants = {
    offscreen: {
      y: 50,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 1.5,
      },
    },
  };

  const toggleCollaborators = () => {
    setShowCollaborators(!showCollaborators);
  };


  return (
    <motion.div
      className="rounded-xl overflow-hidden shadow-lg"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.25 }}
      variants={cardVariants}
    >
      <div
        className="bg-gradient-to-br from-[#8B40E2] via-[#7A90E8] to-[#76D5EF]

p-4 text-white"
      >
        <div className="px-2 py-4">
          <div className="font-bold text-xl mb-1 md:text-2xl">{project.title}</div>
          <p className="text-gray-100 text-base md:text-lg">{project.description}</p>
        </div>
        <div className="px-6 pt-2 pb-2 flex justify-between items-center">
          {/* Conditionally render GitHub icon if githubUrl exists */}
          {project.githubUrl && (
            <a
              href={project.githubUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-gray-300 transition duration-300 text-2xl md:text-4xl"
            >
              <FontAwesomeIcon icon={faGithub} />
            </a>
          )}
          

          {project.collaborators && (
          <button onClick={toggleCollaborators} className="hover:text-gray-300 transition duration-300 cursor-pointer text-2xl md:text-4xl">
            <FontAwesomeIcon icon={faUsers}  />
          </button>
        )}



          {/* Conditionally render link icon if projectUrl exists */}
          {project.projectUrl && (
            <a
              href={project.projectUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-gray-300 transition duration-300 text-2xl md:text-4xl"
            >
              <FontAwesomeIcon icon={faLink}  />
            </a>
          )}

        </div>
        {showCollaborators && (
        <div className="mt-4 bg-white text-black p-3 rounded-lg">
          <h4 className="font-bold">Collaborators:</h4>
          {project.collaborators.map((collaborator, index) => (
            <p key={index}>{collaborator}</p>
          ))}
        </div>
        )}
      </div>
      {project.imageUrl && (
        <img
          src={`./codeProjectImages/${project.imageUrl}`}
          alt={`Preview of ${project.title}`}
          className="w-full h-56 object-cover rounded-b-xl" // This will cover the area of div with the image without stretching it.
        />
      )}
    </motion.div>
  );
};

export default ProjectCard;
