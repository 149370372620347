import React, { useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';

function AboutMe() {
    const controls = useAnimation();
    const aboutMeRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    controls.start({ opacity: 1, transition: { duration: 3 } });
                    observer.unobserve(entry.target); // Stop observing after animation
                }
            },
            { threshold: 0.5 }
        );

        if (aboutMeRef.current) {
            observer.observe(aboutMeRef.current);
        }

        return () => {
            if (aboutMeRef.current) {
                observer.unobserve(aboutMeRef.current);
            }
        };
    }, [controls]);

    return (
        <motion.div ref={aboutMeRef}
            className=" mx-6"
            initial={{ opacity: 0 }}
            animate={controls}
        >
            <div className="text-5xl font-semibold  text-white leading-tight">Hi, I'm Matthew. A Creator.</div>
            <div className=" mt-5 text-2xl font-semibold text-white">I am currently exploring the fields of AI, art, and the intersection of the two to make interactive experiences.</div>
        </motion.div>
    );
}

export default AboutMe;
