import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { InView } from "react-intersection-observer";

const ArtCard = ({ title, photos, description, folder, extra }) => {
  const [expanded, setExpanded] = useState(false);
  const [highQualityImagesLoaded, setHighQualityImagesLoaded] = useState({});
  const [inView, setInView] = useState(false);

  const toggleExpanded = () => setExpanded(!expanded);

  // Function to load high-quality image
  const loadHighQualityImage = (photo) => {
    const image = new Image();
    image.src = `./artProjectImages/${folder}/${photo}`;
    image.onload = () => setHighQualityImagesLoaded(prev => ({ ...prev, [photo]: true }));
  };

  // Effect to load high-quality images once the component is in view
  useEffect(() => {
    if (inView) {
      photos.forEach(photo => loadHighQualityImage(photo));
    }
  }, [inView, photos]);

  // add a new button
  
  return (
    <InView as="div" onChange={(inView) => setInView(inView)} threshold={0.8} triggerOnce={true}>
      <motion.div
        initial={{ opacity: 0, scale: 0.9, y: 50 }}
        animate={inView ? { opacity: 1, scale: 1, y: 0 } : {}}
        transition={{ duration: 1.5, type: "spring" }}
      >
        <div className="text-xl font-bold mt-4 text-white sm:text-2xl lg:text-3xl">{title}</div>
        <div className="text-sm text-white mt-4 sm:text-base lg:text-xl mx-6">{description}</div>
        {extra && <div className="text-sm text-white italic lg:text-base mt-4">{extra}</div>}
        <div className="flex mx-8 gap-5 flex-row justify-center mt-6 sm:mx-12 sm:gap-6 md:mx-20 md:gap-7 lg:mx-28 lg:gap-9 xl:mx-36 xl:gap-11">
          {photos.slice(0, 2).map((photo, index) => (
            <img
              key={index}
              src={highQualityImagesLoaded[photo] ? `./artProjectImages/${folder}/${photo}` : `./artProjectImages/${folder}/lq_${photo}`}
              alt={`Art ${index}`}
              className="w-1/2 rounded-lg"
            />
          ))}
        </div>
        {expanded && (
          <div className="mt-4">
            <div className="grid grid-cols-2 gap-5 justify-center mx-6 sm:mx-10 sm:gap-6 md:mx-16 md:gap-7 lg:mx-24 lg:gap-9 xl:mx-32 xl:gap-11">
              {photos.length > 2 && photos.slice(2).map((photo, index) => (
                <img
                  key={index}
                  src={highQualityImagesLoaded[photo] ? `./artProjectImages/${folder}/${photo}` : `./artProjectImages/${folder}/lq_${photo}`}
                  alt={`Art ${index + 2}`}
                  className="mt-2 rounded-lg"
                />
              ))}
            </div>
          </div>
        )}
        <FontAwesomeIcon
          icon={expanded ? faAngleDown : faAngleUp}
          className="h-6 w-6 cursor-pointer text-white mt-7"
          onClick={toggleExpanded}
        />
      </motion.div>
    </InView>
  );
};

export default ArtCard;
