import Header from "../components/header.js";
import Hero from "../components/hero.js";
import FeatureLandSea from "../components/featureLandSea.js";
import FeatureGenesisAIVideo from "../components/featureGenesisAIVideo.js";
import Footer from "../components/footer.js";
import AboutMe from "../components/aboutMe.js";

function Home() {
  

  return (
    <div className="min-h-screen bg-gradient-to-tr from-gray-800 via-black to-black flex flex-col text-center items-center">
      <Header />
      <div className="mt-52">
        <Hero />
      </div>
      <div className="mt-44 ">
        <FeatureLandSea />
      </div>
      <div className="mt-52">
      <FeatureGenesisAIVideo />
      </div>
      <div className="mt-44">
        <AboutMe />
      </div>
      <div className="mt-32"><Footer /></div>
    </div>
  );
}

export default Home;
