import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";


const FeatureGenesisAIVideo = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const videoVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 1, ease: "easeOut" },
    },
  };

  return (
    <div ref={ref} className="relative overflow-hidden">
      <motion.video
        initial="hidden"
        animate={controls}
        variants={videoVariants}
        loop
        controls
        className="w-full h-auto"
        style={{ zIndex: 1 }}
      >
        <source src="./GenesisAIVideo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
        
      </motion.video>
      <div className="text-3xl font-semibold  text-white leading-tight mt-10">
              Genesis
              </div>
    </div>
  );
};

export default FeatureGenesisAIVideo;
